import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useSelector } from 'react-redux';
import { graphql, navigate, Link } from 'gatsby';
import { MathJaxContext, MathJax } from 'better-react-mathjax';
import { useMediaQuery } from 'usehooks-ts';
import { Layout, SEO } from '../components';
import DocumentsList from '../components/documents/DocumentsList';
import Clouds from '../assets/images/clouds.svg';
import FolderIcon from '../assets/icons/folder.svg';
import ChevronIcon from '../assets/icons/chevron.svg';
import PlusIcon from '../assets/icons/plus.svg';
import LockIcon from '../assets/icons/lock.svg';

import { CountdownTimer } from '../components/UI/FlipClock';
import VideoPlayer from '../components/VideoPlayer';
import TinyMCEViewer from '@src/components/Common/TinyMCEViewer/TinyMCEViewer';

const ModuleSubCategoryPage = ({
  data,
  data: {
    subCategoryOption: { edges },
    subCategoryPage: { module, heading: subPageCategoryHeading },
    timers,
  },
}) => {
  process.env.NODE_ENV == 'development' &&
    console.log('🚀 module-sub-category-sub-category-options.js', data);
  const screen479 = useMediaQuery('(max-width: 479px)');
  const {
    id,
    description,
    description2,
    descriptionBelow,
    subCategoryHeading,
    featuredDocument,
    subCategoryOptionsList,
    subCategoryOptions,
    seo,
    seoKeywords,
    subscriptionforumbuttons,
    video,
    videos,
    youtubeVideo,
  } = edges[0].node;

  const user = useSelector((state) => state.user.data);
  const isSubscribed = user?.subscribed ?? false;

  const showSubscriptionButton = subscriptionforumbuttons?.[0]?.displaysubscription ?? false;
  const showForumButton = subscriptionforumbuttons?.[0]?.displayforum ?? false;
  const subscriptionBackgroundColor =
    subscriptionforumbuttons?.[0]?.subbackgroundcolor?.hex ?? '#23D4FF';
  const subscriptionTextColor = subscriptionforumbuttons?.[0]?.subtextcolor?.hex ?? '#000000';
  const forumBackgroundColor =
    subscriptionforumbuttons?.[0]?.forumbackgroundcolor?.hex ?? '#FD99C4';
  const forumTextColor = subscriptionforumbuttons?.[0]?.forumtextcolor?.hex ?? '#000000';

  const orderedTimers = React.useMemo(() => {
    return timers?.group?.map(({ edges, examBoard }) => {
      const groups = {};
      for (const {
        node,
        node: { title },
      } of edges) {
        const regex = /\d([A-Za-z])(?![A-Za-z])/;
        const matched = title?.match(regex);

        if (!matched) {
          if (!groups.unmatched) groups.unmatched = [];
          groups.unmatched = [...groups.unmatched, node];
          continue;
        }

        const [_, letter] = matched;
        if (!groups[letter]) {
          groups[letter] = [];
        }
        groups[letter].push(node);
      }
      const formattedEdges = Object.values(groups)
        .map((papers) => papers.sort((a, b) => b.title - a.title))
        .flat();
      return {
        edges: formattedEdges,
        examBoard,
      };
    });
  }, [timers]);

  const numOfTimers = orderedTimers.reduce((total, { edges }) => (total += edges?.length ?? 0), 0);

  const allVideos = [
    ...(video?.video?.streamingUrl ? [video?.video?.streamingUrl] : []),
    ...(youtubeVideo ? youtubeVideo.split(',') : []),
    ...(videos?.length ? videos.map((vid) => vid.video.streamingUrl) : []),
  ];

  const renderSubCategoryOptions = (subCategoryOptions) => {
    if (!subCategoryOptions){
      return null;
    }
    const groupedOptions = {};
    const groupedDescriptions = {};
    subCategoryOptions.forEach((o) => {
      const heading = o.groupHeader || '';
      groupedOptions[heading] = groupedOptions[heading] || [];
      groupedOptions[heading].push(o);
      if (o.groupHeaderDescription) {
        groupedDescriptions[heading] = o.groupHeaderDescription;
      }
    })
    return (
      <div>
        {
          Object.keys(groupedOptions).sort().map((heading) => {
            return (
              <div>
                <br />
                {
                  heading !== '' ? (
                    <>
                      <h2 style={{textAlign: 'center', marginBottom: "40px"}}>{heading}</h2>
                      {groupedDescriptions[heading] && (
                        <div className="container content-block" style={{marginBottom: "40px"}}>
                          <div dangerouslySetInnerHTML={{ __html: groupedDescriptions[heading] }} />
                        </div>
                      )}
                    </>

                  ) : null
                }
                <ul className="category-options">{groupedOptions[heading].map(renderSubCategory)}</ul>
              </div>
            )
          })
        }
      </div>
    ) 
  }

  const renderSubCategoryOption = (optionHeaderSlug, subCategoryOption) => {
    const isLocked = subCategoryOption.isLocked;
    const lockedLink = isLocked ? `${subCategoryOption.slug}/locked` : subCategoryOption.slug;
    const link = optionHeaderSlug ? `${optionHeaderSlug}/${lockedLink}` : lockedLink;
    return (
      <li className="category-option" key={subCategoryOption.id}>
        <img src={FolderIcon} className="category-option-image" alt="Folder" />
        <div className="category-option-text">
          <Link to={link} className="category-option-link">
            {subCategoryOption.name}
          </Link>
        </div>
        {isLocked && <img src={LockIcon} className="lock-icon" alt="Locked" />}
      </li>
    );
  };

  const renderSubCategory = (subCategory, index) => {
    return (
      <>
        {renderSubCategoryOption(null, {
          isLocked: false,
          slug: subCategory?.slug,
          name: subCategory?.name,
        })}
      </>
    );
  };

  const newSubCategoryOptions = subCategoryOptions.map(
    ({ subCategoryOptionHeading, groupHeader, groupHeaderDescription }) => {return {...subCategoryOptionHeading, groupHeader, groupHeaderDescription}},
  );

  const renderActionButtons = () => {
    if (!showSubscriptionButton && !showForumButton) return;
    return (
      <div className="action-container">
        {showSubscriptionButton && !isSubscribed && (
          <button
            onClick={() => navigate('/subscriptions')}
            className="w-nav-brand sign-in-badge forum-sub-button"
            style={{
              backgroundColor: subscriptionBackgroundColor,
              color: subscriptionTextColor,
            }}
          >
            <span class="2">Join Now</span>
          </button>
        )}
        {showForumButton && (
          <button
            onClick={() => navigate('/forum')}
            className="w-nav-brand sign-in-badge forum-sub-button"
            style={{
              backgroundColor: forumBackgroundColor,
              color: forumTextColor,
            }}
          >
            <span class="2">Forum</span>
          </button>
        )}
      </div>
    );
  };

  return (
    <MathJaxContext>
      <Layout>
        <SEO
          description={seo && seo.description}
          title={seo && seo.title}
          image={seo && seo.image}
          seoKeywords={seoKeywords}
        />
        <div>
          <div className="container">
            {!!orderedTimers?.length &&
              orderedTimers.map(
                ({ edges, examBoard }, i) =>
                  !!edges?.length && (
                    <React.Fragment key={`wrapper-${i}`}>
                      {examBoard !== 'No exam board' && (
                        <p className="1.25 bold mb-1">{examBoard}</p>
                      )}
                      <div
                        className={`srow mobile:x-centered ${numOfTimers < 4 ? 'x-centered' : ''}`}
                      >
                        {edges.map(({ endDate, title, daysOnly }, k) => (
                          <div className="column narrow mb-1" key={`clock ${k}`}>
                            <CountdownTimer endDate={endDate} title={title} daysOnly={daysOnly} />
                          </div>
                        ))}
                      </div>
                    </React.Fragment>
                  ),
              )}
            <div className="grid hero" style={{ paddingTop: '50px' }}>
              <div className="content-block" style={{ minHeight: screen479 ? 'unset' : '630px' }}>
                {/* <div style={{ marginBottom: '5px' }}> */}
                {!screen479 && module.image?.url && (
                  <img
                    src={module.image.url}
                    alt={module.image.alt}
                    style={{ float: 'right', width: '60%' }}
                  />
                )}
                {renderActionButtons()}
                <a className="page-breadcrums" href={`/modules/${module.slug}`}>
                  {module.title}
                </a>
                {' > '}
                <a
                  className="page-breadcrums"
                  href={`/modules/${module.slug}/${subPageCategoryHeading.slug}`}
                >
                  {subPageCategoryHeading.name}
                </a>
                {/* </div> */}
                <h1 className="content-h2">{subCategoryHeading[0].name}</h1>
                {allVideos.length
                  ? allVideos.map((src) => (
                      <VideoPlayer className="video-player" isSmall src={src} />
                    ))
                  : null}
                <br />
                <MathJax hideUntilTypeset="first">
                  {description && <ReactMarkdown className="paragraph" children={description} />}
                  {!description && description2 && (
                    <TinyMCEViewer content={description} />
                  )}
                </MathJax>
              </div>
              {screen479 && module.image?.url && (
                <img src={module.image.url} alt={module.image.alt} />
              )}
            </div>
          </div>
          <img src={Clouds} alt="Clouds pattern" className="pattern-left" />
        </div>
        <article className="content-section" style={{ clear: 'both' }}>
          <div className="content-block container">
            {featuredDocument.length > 0 && (
              <div>
                <br />
                {<DocumentsList documents={featuredDocument} />}
              </div>
            )}
            {
              renderSubCategoryOptions(newSubCategoryOptions)
            }
            {/* <div className={categoryCssClasses}>{subCategoryList.map(renderSubCategory)}</div> */}
          </div>
        </article>
        {descriptionBelow && (
          <div className="container content-block" style={{paddingBottom: "50px"}}>
            <TinyMCEViewer content={descriptionBelow} />
          </div>
        )}
      </Layout>
    </MathJaxContext>
  );
};

export default ModuleSubCategoryPage;

const query = graphql`
  query subCategoryPageQueryAndSubCategoryPageOptionsQuery(
    $subCategoryId: String!
    $subCategoryPageId: String!
    $pathName: String
  ) {
    timers: allDatoCmsCountdownTimer(filter: { path: { eq: $pathName } }, sort: { fields: title }) {
      group(field: examBoard) {
        edges {
          node {
            daysOnly
            endDate
            examBoard
            path
            title
          }
        }
        examBoard: fieldValue
      }
    }
    subCategoryPage: datoCmsModuleSubCategoryPage(id: { eq: $subCategoryPageId }) {
      module {
        title
        slug
        image {
          alt
          url
        }
      }
      heading {
        name
        slug
      }
    }
    subCategoryOption: allDatoCmsSubCategory(filter: { id: { eq: $subCategoryId } }) {
      edges {
        node {
          id
          description
          description2
          descriptionBelow
          subCategoryHeading {
            id
            name
            slug
          }
          video {
            video {
              streamingUrl
            }
          }
          videos {
            video {
              streamingUrl
            }
          }
          youtubeVideo
          subscriptionforumbuttons {
            displayforum
            displaysubscription
            subbackgroundcolor {
              hex
            }
            subtextcolor {
              hex
            }
            forumbackgroundcolor {
              hex
            }
            forumtextcolor {
              hex
            }
          }
          featuredDocument {
            id: originalId
            name
            displayName
            freeDocument
            freeSample
            hasPassword
            fileUrl
            originalId: id
          }
          subCategoryOptions {
            subCategoryOptionHeading {
              id
              name
              slug
            }
            groupHeader
            groupHeaderDescription
          }
          seo {
            description
            title
            image {
              url
            }
          }
          seoKeywords {
            keyword
          }
        }
      }
    }
  }
`;

export { query };
